<template>
  <div id="box">
    <Header> 设置 </Header>

    <div class="box">
      <div class="box_one" @click="datum">
        <div class="one_datum">
          <img src="../../../static/image/compile.png" alt="" />
          <span>编辑资料</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
      <div class="box_one" @click="safety">
        <div class="one_datum">
          <img src="../../../static/image/safe.png" alt="" />
          <span>安全中心</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
      <div class="box_one" @click="invite">
        <div class="one_datum">
          <img src="../../../static/image/invite.png" alt="" />
          <span>推荐有礼</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
      <div class="box_one" @click="ourselves">
        <div class="one_datum">
          <img src="../../../static/image/concerning.png" alt="" />
          <span>关于我们</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
      <div class="box_one" @click="$router.push('/commonProblem')">
        <div class="one_datum">
          <img src="../../../static/image/doubt.png" alt="" />
          <span>客服/常见问题</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
      <div class="box_one" @click="$router.push('/feedback')">
        <div class="one_datum">
          <img src="../../../static/image/opinion.png" alt="" />
          <span>意见反馈</span>
        </div>
        <img src="../../../static/image/right.png" alt="" />
      </div>
    </div>
    <div class="quit" @click="quit">退出登录</div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    //关于我们
    ourselves() {
      this.$router.push('/ourselves');
    },
    // 邀请设置
    invite() {
      this.$router.push('/invite');
    },
    // 退出登录
    quit() {
      let params = {
        token: localStorage.getItem('token'),
      };
      this.$api.logout(params).then((res) => {
        if (res.code == 0) {
          this.$toast(res.msg);
          this.$router.push('/user');
          localStorage.removeItem('token');
        }
      });
    },

    safety() {
      this.$router.push('/Safety');
    },
    // 编辑资料
    datum() {
      this.$router.push('/Datum');
    },
  },
};
</script>
<style lang="less" scoped>
#box {
  height: 100vh;
  width: 100%;
  background-color: #f3f4f8;
}

.quit {
  width: 341px;
  height: 46px;
  color: #fe2c2c;
  font-size: 13px;
  text-align: center;
  line-height: 46px;
  background-color: #ffffff;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.box {
  width: 100%;
  padding-top: 21px;
  .box_one {
    width: 341px;
    margin-left: 17px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 5px;
    margin-bottom: 17px;
  }
  img {
    width: 7px;
    height: 12px;
    padding-left: 17px;
    padding-right: 10px;
  }
  .one_datum {
    display: flex;
    img {
      display: block;
      width: 17px;
      height: 17px;
    }
    span {
      font-size: 13px;
    }
  }
}
</style>
